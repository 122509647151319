import './Welcome.css';

const Welcome = (props) => {
  return (
    <div className="Welcome">
      <h1 className="heading prevent-select">Hey there, I'm<br/><span className="name">lstuma</span></h1>
    </div>
  );
}

export default Welcome;
