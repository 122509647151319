import './Tobbar.css';
import Navbar from '../Navbar/Navbar.js';
import Logo from '../Logo/Logo.js';

const Tobbar = () => {
  return (
    <div className="Tobbar">
      <Logo/>
      <Navbar/>
    </div>
  );
}

export default Tobbar;
