import React, { useState, useEffect } from 'react'
import './Screen.css';

const ProjectPage = (props) => {
    return (
        <div className="ProjectPage">
            <h1 className="heading" style={{color: props.color}}>{props.name}</h1>
            <p className="description">{props.description}</p>
            <a href={props.link} className="link prevent-select"><span className="bracket">[</span> Link <span className="bracket">]</span></a>
            <p onClick={() => {props.projectHook();}} className="next-btn prevent-select">Next <span className="bracket">></span></p>
        </div>
    );
}

const Screen = (props) => {
    const [project, setProject] = useState(0);
    const nextProject = () => {
        setProject((project+1)%props.projects.length);
    };
    return (
        <div className="Screen">
            <ProjectPage name={props.projects[project][0]} description={props.projects[project][1]} link={props.projects[project][2]} projectHook={nextProject} color={props.projects[project][3]}/>
        </div>
        );
}

export default Screen;
