import { FiArrowUpRight } from "react-icons/fi";
import './Project.css';

const Project = (props) => {
  return (
    <div className={"Project glowy-"+props.color}>
      <div className="img-container">
        <img className="img prevent-select" alt="" src={process.env.PUBLIC_URL + "/img/disc_black.png"}/>
      </div>
      <div className="grid-2col details">
        <h1 className="heading">{props.name}</h1>
        <a href={props.link} className="attached-link">
          <FiArrowUpRight/>
        </a>
      </div>
    </div>
  );
}

export default Project;
