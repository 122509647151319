import React, { useState, useEffect } from 'react'
import { FaSearch } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import { FaFire } from "react-icons/fa";
import { RiEyeFill } from "react-icons/ri";
import { FaQuestionCircle } from "react-icons/fa";
import queryString from 'query-string';
import BlogArticle from './components/BlogArticle/BlogArticle.js';
import Article from './components/Article/Article.js';
import { useNavigate } from 'react-router-dom';
import './Blog.css';

// prism
import '../../global/prism/prism.css';

import {fetch_meta, sort_articles, fetch_article, search_articles} from './BlogAPI.js';

const Blog = (props) => {
  const [ogmeta, setOgMeta] = useState([]);
  const [meta, setMeta] = useState([]);
  const [sortby, setSortby] = useState("time");
  const [article, setArticle] = useState(null);
  const [liked, setLiked] = useState(false);
  const [opened, setOpened] = useState(false);
  const [update, setUpdate] = useState(0);
  const [searchterm, setSearchterm] = useState("");
  // height of article
  const [articleHeight, setArticleHeight] = useState(0);

  const navigate = useNavigate();

  // get id of article if existent
  let id = window.location.href.split('/').at(-1);
  id = id.split('?')[0].split('#')[0];
  
  useEffect( () => {
    if(/^-?\d+$/.test(id)) {
      handle_request(id);
    }
  }, []);

  // grab metainfo
  const grab_metainfo = async () => {
      let data = await fetch_meta(); 
      if(searchterm.length !== 0) data = search_articles(searchterm, data);
      data = sort_articles(sortby, data); 
      const rows = data.reduce(function (rows, key, index) { 
        return (index % 2 == 0 ? rows.push([key]) : rows[rows.length-1].push(key)) && rows;
      }, []);
      setMeta(rows);
  };

  useEffect( () => {
    if(article)
      setLiked(JSON.parse(localStorage.getItem('liked-'+article.id)));
  }, [article]);
  useEffect( () => {
    if(article)
      localStorage.setItem('liked-'+article.id, JSON.stringify(liked));
      setUpdate(update+1);
  }, [liked]);

  useEffect( ()=>{
    grab_metainfo();
  }, [sortby, searchterm]);

  const handle_request = async (id) => {
    navigate('/blog/'+id, { shallow: true });
    let data = await fetch_article(id); setArticle(data); setOpened(true);
  };
  const close_article = async () => { setOpened(!opened);};
  const like_article = async() => { setLiked(!liked); };
  const search_term = async(event) => { setSearchterm(event.target.value); };
  const heightHook = async(height) => { setArticleHeight(height); }

  return (
    <div className="Blog" style={{"height": "calc(" + articleHeight+"px" + " - 26rem)"}}>
      <div className="navigation">
        <div className="searchbar">
          <FaSearch size={"1.5rem"}/>
          <input type="text" placeholder="Search" onChange={search_term} name="search" className="searchbar-input"/>
        </div>
        <div className="sortby">
          <input type="radio" name="sort" onChange={() => {setSortby('time');}} id="sortby-age" value="time" defaultChecked/>
          <label htmlFor="sortby-age"><FaClock size={"1.5em"}/></label>
          <input type="radio" name="sort" onChange={() => {setSortby('hot');}} id="sortby-hot" value="hot"/>
          <label htmlFor="sortby-hot"><FaFire size={"1.5em"}/></label>
          <input type="radio" name="sort" onClick={() => {setSortby('random'); grab_metainfo();}} id="sortby-random" value="random"/>
          <label htmlFor="sortby-random"><FaQuestionCircle size={"1.5em"}/></label>
        </div>
      </div>
        {meta.map(pair => (     
           <div className="article-container">
            {pair.map(data => (<BlogArticle name={data.title} liked={JSON.parse(localStorage.getItem('liked-'+data.id))} id={data.id} handler={handle_request} likes={data.likes} date={data.date}/>))}
           </div>
        ))}
      {article!=null?<Article data={article} liked={liked} heightHook={heightHook} like={like_article} close={close_article} open={opened}/>:<></>}
    </div>
  );
}

export default Blog;
