import * as THREE from 'three';
import { useGLTF } from '@react-three/drei/core/useGLTF';
import './Model.css';
import { FlakesTexture } from 'three-stdlib';

export const GrabModel = (gltf) => {
    const { nodes, materials } = useGLTF("/models/"+gltf);
    return [ nodes, materials ];
}

export const Model = (props) => {
    const { nodes, mats } = useGLTF("/models/"+props.gltf);
    return (
        <group {...props} position={props.objposition} scale={props.objscale?props.objscale:1} rotation={props.objrotation}>
            {Object.values(nodes).map((node, id) => {
                return (<mesh key={id} geometry={node.geometry} castShadow={props.castShadow} receiveShadow={props.receiveShadow} material={node.material}></mesh>);
            })}
        </group>
    );
}
