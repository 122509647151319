import pic from './lstumapfp.png';
import './Profilepic.css';

const Profilepic = (props) => {
  return (
    <img className="Profilepic" src={pic}/>
  );
}

export default Profilepic;
