import './Projects.css';
import * as THREE from 'three'
import React, { Suspense, useRef } from 'react'
import BlackRegion from '../../components/Regions/BlackRegion.js';
import Chef from './components/Chef/Chef.js';
import Hackathons from './components/Hackathons/Hackathons.js';
import Project from './components/Project/Project.js';
import Screen from './components/Screen/Screen.js';
import { Canvas, useFrame } from "react-three-fiber";
import { Stage, PresentationControls, Html, OrbitControls, ContactShadows, Environment } from "@react-three/drei";
import { Model, GrabModel } from '../../components/3D/Model.js';
import  MouseRotateModel from '../../components/3D/MouseRotateModel.js';
import RotateableModel from '../../components/3D/RotateableModel.js';


const PcModel = (props) => {
  const group = useRef();
  const group2 = useRef();
  const group3 = useRef();
  // Make it float
  useFrame((state) => {
    const t = state.clock.getElapsedTime();
  });
  // Load model
  const [ pcnodes, pcmats ] = GrabModel('laptop3.glb');
  // Make it float
  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    group.current.rotation.x = THREE.MathUtils.lerp(group.current.rotation.x, Math.cos(t / 2) / 10, 0.1);
    group.current.rotation.y = THREE.MathUtils.lerp(group.current.rotation.y, Math.sin(t / 4) / 10, 0.1);
    group.current.rotation.z = THREE.MathUtils.lerp(group.current.rotation.z, Math.sin(t / 4) / 20, 0.1);
    group.current.position.y = THREE.MathUtils.lerp(group.current.position.y, (-2 + Math.sin(t / 2)) / 30, 0.1);

    group2.current.rotation.x = group.current.rotation.x*.1;
    group2.current.rotation.y = -group.current.rotation.y;
    group2.current.rotation.z = -group.current.rotation.z*.1;
    group2.current.position.y = group.current.position.y*.1;

    group3.current.rotation.x = group.current.rotation.x;
    group3.current.position.y = group2.current.position.y;
  });
  return (
    <group position={[0, -1, -20]} scale={30}>
      <group ref={group2} {...props} dispose={null}>
        <Model gltf={"projects.glb"} objscale={.1} castShadow receiveShadow/>
        <group ref={group}>
          <Model gltf={"projects_floaters.glb"} objscale={.1}  castShadow receiveShadow/>
        </group>
        <PresentationControls snap={true} speed={1} zoom={1} polar={[-.1, .1]} azimuth={[-.3, .3]}>
        <group ref={group3}>
          <group position={[-11.54, -0.055, -0.09]} scale={320}>
               <mesh geometry={pcnodes['Laptop-Mesh_1'].geometry}>
                {/* Drei's HTML component can "hide behind" canvas geometry */}
                <Html className="content" transform occlude position={[.036064, 0.000395, .00014700]} scale={[.000043, .000046, .000044]}>
                  <div className="wrapper" onPointerDown={(e) => e.stopPropagation()}>
                    <Screen projects={props.projects}/>
                  </div>
                </Html>
              </mesh>

            { /* Laptop Base */ }
            <mesh geometry={pcnodes['Cube025'].geometry} castShadow><meshStandardMaterial side={THREE.DoubleSide} metalness={.5} roughness={0.1} attach="material" color={"hotpink"}/></mesh>
            { /* Trackpad */ }
            <mesh geometry={pcnodes['Cube026'].geometry} castShadow><meshStandardMaterial side={THREE.DoubleSide} metalness={.3} roughness={0} attach="material" color={"blue"}/></mesh>
            { /* keys row 1 */ }
            <mesh geometry={pcnodes['Cube027'].geometry} castShadow><meshStandardMaterial side={THREE.DoubleSide} metalness={.2} roughness={.8} attach="material" color={"yellow"}/></mesh>
            { /* keys row 2 */ }
            <mesh geometry={pcnodes['Cube031'].geometry} castShadow><meshStandardMaterial side={THREE.DoubleSide} metalness={.2} roughness={.8}  attach="material" color={"yellow"}/></mesh>
            { /* keys row 3 */ }
            <mesh geometry={pcnodes['Cube032'].geometry} castShadow><meshStandardMaterial side={THREE.DoubleSide} metalness={.2} roughness={.8} attach="material" color={"yellow"}/></mesh>
            { /* lower left key row 3 */ }
            <mesh geometry={pcnodes['Cube033'].geometry} castShadow><meshStandardMaterial side={THREE.DoubleSide} metalness={.2} roughness={.8}  attach="material" color={"yellow"}/></mesh>
            { /* star upper right */ }
            <mesh geometry={pcnodes['Stars013'].geometry} castShadow><meshStandardMaterial side={THREE.DoubleSide} metalness={1.0} roughness={0} attach="material" color={"yellow"}/></mesh>
            { /* star lower left */ }
            <mesh geometry={pcnodes['Stars014'].geometry} castShadow><meshStandardMaterial side={THREE.DoubleSide} metalness={1.0} roughness={0}  attach="material" color={"yellow"}/></mesh>
            { /* screen frame */ }
            <mesh geometry={pcnodes['Laptop-Mesh'].geometry} castShadow><meshStandardMaterial side={THREE.DoubleSide} metalness={.5} roughness={.1} attach="material" color={"hotpink"}/></mesh>

          </group>
        </group>
        </PresentationControls>
      </group>
    </group>
    )
}

const Projects = (props) => {
  const projects = [
    ["Santa()", "A small compiler for a small and simple programming language.", "https://github.com/lstuma/Santa-", "red"],
    ["Fractals", "A collection of python scripts for rendering fracvtals.", "https://github.com/lstuma/fractals", "orange"],
    ["Pigeon", "Python Web Framework", "https://github.com/TeamSmil3y/PigeonPost", "blue"],
    ["Paper2Box", "UML-Diagram Editor with integrated AI-Powered digitalization.", "https://github.com/lstuma/Paper2Box", "red"],
    ["WePlan", "Smart planning tool for construction", "https://github.com/lstuma/WePlan", "blue"],
    ["HubHopper", "A ridesharing app designed to encourage sustainable change", "https://github.com/TeamSmil3y/hackaburg_backend", "purple"],
    ["WintR", "An event planning app", "https://github.com/lstuma/WintR", "green"],
    ];
  const hackathons = [
    ["Hackaburg", "2023", "lots of fun", "https://hackaburg.de/2023"],
    ["SDaCathon", "2022", "lots of fun", "https://sdacathon2022.devpost.com/"],
    ["Ostalb Hackathon", "2022", "lots of fun", "https://www.linkedin.com/posts/lukas-stockmann-640405239_digitaltwin-teamsmiley-activity-6991268177447944192-Rwko?utm_source=share&utm_medium=member_desktop"],

  ];
  return (
    <div className="Projects">
      <Chef/>
      <div className="pc-model-container">
        <Canvas shadows camera={{ position: [0, -.05, .8], fov: 40}}>
          <Suspense fallback={null}>
            <pointLight
              position={[2.5, 4, -10]}
              intensity={150}
              castShadow
              color="orange"
            />
            <PcModel projects={projects}/>
            { /* available environment presets: apartment, city, dawn, forest, lobby, night, park, studio, sunset, warehouse */ }
            <Environment castShadow preset="lobby" environmentIntensity={.5}/>
          </Suspense>
        </Canvas>

      </div>

      <BlackRegion>
        <h1 className="heading">My Projects</h1>
        <div style={{padding: "1.5em 0"}} className="project-container-wrapper">
          <div className="project-container" style={{gridTemplateColumns: 3, justifyContent: "center", position: "relative", display: "flex"}}>
            {projects.map((project, i) => {
              return <Project name={project[0]} color={project[3]} link={project[2]} key={i}/>;
            })}
          </div>
        </div>
        <Hackathons hackathons={hackathons} />
      </BlackRegion>
    </div>
  );
}

export default Projects;
