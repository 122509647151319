import './Logo.css';

const Logo = () => {
  return (
    <div className="Logo prevent-select">
      <h1>lstuma<span style={{"color": "#303030"}}>.dev</span></h1>
    </div>
  );
}

export default Logo;
