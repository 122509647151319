import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useEffect } from 'react';

import './NavElement.css';

const NavElement = (props) => {
  const [status, setStatus] = useState(props.status);
  const location = useLocation();
  useEffect(() => {
    if(location.pathname.startsWith(props.link) && (props.link !== "/" || location.pathname === props.link)) setStatus("selected");
    else setStatus("none");
  }, [location, props.link]);
  return (
    <Link to={props.link} onClick={() => {setStatus("routed")}}>
      <div className="NavElement prevent-select" status={status}>
        <div className="IconContainer">{ props.children }</div>
        <div className="TextContainer"><p> { props.text } </p></div>
      </div>
    </Link>
  );
}

export default NavElement;
