import { RiHomeFill } from "react-icons/ri";
import { FaBookDead } from "react-icons/fa";
import { IoMdContact } from "react-icons/io";
import { IoMdCube } from "react-icons/io";

import './Navbar.css';
import NavElement from './NavElement.js';

const Navbar = () => {
  return (
    <div className="Navbar">
      <NavElement text="Home" status="routed" link="/"><RiHomeFill size={25}/></NavElement>
      <NavElement text="Blog" status="none" link="/blog"><FaBookDead size={25}/></NavElement>
      <NavElement text="Projects" status="none" link="/projects"><IoMdCube size={32}/></NavElement>
    </div>
  );
}

export default Navbar;
