import logo from './logo.svg';
import './App.css';
// fonts (too much css to be included in fonts.css)
import './global/Poppins.css';
// general fonts
import './global/Fonts.css';
import './global/Global.css';
import './global/Additional.css';

import Home from './pages/Home/Home.js';
import Blog from './pages/Blog/Blog.js';
import Projects from './pages/Projects/Projects.js';

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Tobbar from './common/Tobbar/Tobbar.js';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Tobbar/>
        <div style={{marginTop:"10em", display: "grid"}}>
          <Routes>
            <Route path="" element={<Home />} />
            <Route path="blog" element={<Blog />} />
            <Route path="blog/:id" element={<Blog/>} />
              <Route path="projects" element={<Projects />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
