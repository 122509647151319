import React, { useState, useEffect, useRef } from 'react'
import { FiArrowUpRight } from "react-icons/fi";
import { PiHeartFill } from "react-icons/pi";
import { FaCalendarDay } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import './Article.css';

// Prism
import Prism from "prismjs";
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-c';
import 'prismjs/components/prism-cpp';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-ebnf';
import 'prismjs/components/prism-java';

import 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace';
import 'prismjs/plugins/autoloader/prism-autoloader';
import 'prismjs/plugins/line-numbers/prism-line-numbers';

const Article = (props) => {
  const ref = useRef(null);
  useEffect(() => {
    let func = async() => {
      props.heightHook(props.open?ref.current.clientHeight:"28rem");
    };
    Prism.highlightAll();
    setTimeout(func, 500);
  }, [props.open]);

  return (
    <div className="Article" isopen={props.open?"yes":"no"} liked={props.liked?"yes":"no"} ref={ref}>
      <div className="like" onClick={props.like}><PiHeartFill size={"2em"}/></div>
      <div className="metainfo"><FaCalendarDay size={"1rem"}/><h5>{props.data.date}</h5></div>
      <div className="close" onClick={props.close}><FaAngleDown size={"2rem"}/></div>
      <h1 className="heading">{props.data.title}</h1>
      <div className="content line-numbers"><ReactMarkdown children={props.data.markdown} remarkPlugins={[remarkGfm]} /></div>
    </div>
  );
}

export default Article;
