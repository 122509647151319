import './Home.css';
import Welcome from './components/Welcome/Welcome.js';
import Whoami from './components/Whoami/Whoami.js';
import Contact from './components/Contact/Contact.js'
import BlackRegion from '../../components/Regions/BlackRegion.js';

const Home = (props) => {
  return (
    <div className="Home">
      <Welcome/>
      <BlackRegion>
        <Whoami/>
        <Contact/>
      </BlackRegion>
    </div>
  );
}

export default Home;
