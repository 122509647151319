export const fetch_article = async (id) => {
    let text = await fetch(process.env.PUBLIC_URL+"/blog/articles/"+id+".md").then(data => data.text())
    let lines = text.split('\n')
    text = text.slice(text.indexOf('\n', 2))

    if(lines[0].startsWith('<!DOCTYPE html>') || lines[0].startsWith('<!doctype html>')) { 
        console.error("could not load resource"); 
        return {title: "404", markdown: "404"}; 
    }

    let metadata = await fetch_meta();

    return {title: lines[0], markdown: text, date: metadata[Number(id)-1].date, id: id};
}

export const fetch_meta = async () => {
    // get all articles metadata from meta.csv collection
    let text = await fetch(process.env.PUBLIC_URL+"/blog/meta.csv").then(data => data.text());
    let articles = [];

    for(let line of text.split('\n')) {
        let data = line.split(',');
        articles.push({title: data[0], id: data[1], likes: data[2], date: data[3]});
    }

    return articles;
}

export const sort_articles = (sort_by, articles) => {
    switch(sort_by) {
        case "time":
            // actually sorts by id, but since ids are created counting up it will work
            return articles.sort((a, b) => Number(b.id) - Number(a.id));
        case "hot":
            // sorts by likes
            return articles.sort((a, b) => Number(b.likes) - Number(a.likes));
        case "random":
            // sorts randomly
            return articles.sort((a, b) => 0.5 - Math.random());
    }
}

export const search_articles = (term, articles) => {
    let found = [];
    articles.forEach((article) => {
        if(article.title.toLowerCase().includes(term.toLowerCase()))
            found.push(article);
    });
    return found;
}

export default {fetch_meta, fetch_article, sort_articles, search_articles};