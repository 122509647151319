import React, { Suspense, useRef } from 'react';
import { Stage, PresentationControls, OrbitControls } from "@react-three/drei";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { Model, grab_model } from './Model.js';
import { Vector3 } from 'three'
import * as THREE from 'three'

function Rig() {
    const { camera, mouse } = useThree()
    const vec = new Vector3()

    return useFrame(() => {
        camera.position.lerp(vec.set(mouse.x, mouse.y, camera.position.z), .02)
        camera.lookAt(0, 0, 0)
    })
}

const ModelWithRef = (props) => {
    const group = useRef();
    // Make it float
    useFrame((state) => {
        const t = state.clock.getElapsedTime()
        group.current.rotation.x = THREE.MathUtils.lerp(group.current.rotation.x, Math.cos(t / 2) / 10, 0.1)
        group.current.rotation.y = THREE.MathUtils.lerp(group.current.rotation.y, Math.sin(t / 4) / 10, 0.1)
        group.current.rotation.z = THREE.MathUtils.lerp(group.current.rotation.z, Math.sin(t / 8) / 100, 0.1)
        group.current.position.y = THREE.MathUtils.lerp(group.current.position.y, (-2 + Math.sin(t / 2)) / 100, 0.1)
    });
    return (
        <group ref={group} dispose={null}>
                <Stage environment={"sunset"} shadows={false}>
                    <Model scale={1} gltf={props.gltf}/>
                </Stage>
            <Rig/>
        </group>
        );
}

const FloatyModel = (props) => {
    let fov = props.fov? props.fov:55
    return (
        <Canvas dpr={[1,2]} camera={{fov: fov}} className="model">
            <ModelWithRef {...props}/>
        </Canvas>
        );
}

export default FloatyModel;
