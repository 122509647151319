import { useGLTF } from '@react-three/drei/core/useGLTF';
import MouseRotateModel from '../../../../components/3D/MouseRotateModel.js';
import RotateableModel from '../../../../components/3D/RotateableModel.js';
import FloatyModel from '../../../../components/3D/FloatyModel.js';
import { FaArrowRight } from "react-icons/fa6";
import './Hackathons.css';

const Hackathon = (props) => {
    return (
        <div className="Hackathon">
            <div className="circle"><img src={process.env.PUBLIC_URL + "/img/medal.png"} alt="404" draggable="false" className="icon prevent-select"/></div>
            <div className="container">
                <h1 className="name prevent-select">{props.name}</h1>
                <p className="date prevent-select">{props.date}</p>
            </div>
            <a href={props.link} className="hoverable">
                <FaArrowRight size={"1em"}/>
            </a>
        </div>
    );
}

const Hackathons = (props) => {
    const hackathons = props.hackathons;
    return (
        <div className="Hackathons">
            <h1 className="heading">Hackathons</h1>
            <div className="grid-container">
                <div className="img-container">
                    <img className="prevent-select" draggable="false" alt="404" src={process.env.PUBLIC_URL + "/img/astro_w_flag.png"} style={{transform: "rotate(-10deg)"}}/>
                </div>
                <div className="hackathon-container">
                    {hackathons.map((hackathon, id) => {
                        return (
                            <Hackathon name={hackathon[0]} date={hackathon[1]} description={hackathon[2]} link={hackathon[3]} />
                        );
                    })}
                    <div className="Hackathon Hackathon-more">
                        <h1 className="name prevent-select">• • •</h1>
                    </div>
                </div>
            </div>
        </div>
        );
}

export default Hackathons;
