import { FiArrowUpRight } from "react-icons/fi";
import { PiHeartFill } from "react-icons/pi";
import { FaCalendarDay } from "react-icons/fa";
import './BlogArticle.css';

const BlogArticle = (props) => {
  return (
    <div className="BlogArticle" liked={props.liked?"yes":"no"}>
    	<h1 className="heading">{props.name}</h1>
    	<a className="attached-link" onClick={() => {props.handler(props.id)}}><FiArrowUpRight size={"2em"}/></a>
		<div className="likes-container prevent-select"><h5 className="likes">{props.likes}</h5><PiHeartFill size={"2em"}/></div>
    	<div className="date-container prevent-select"><FaCalendarDay size={"1.2em"}/><h5 className="date">{props.date}</h5></div>
    </div>
  );
}

export default BlogArticle;
