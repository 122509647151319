import './BlackRegion.css';

const BlackRegion = (props) => {
  return (
    <div className="BlackRegion">
      { props.children }
    </div>
  );
}

export default BlackRegion;
