import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { ImMail4 } from "react-icons/im";
import { SiDevpost } from "react-icons/si";
import Contactcard from './Contactcard.js'
import './Contact.css';

const Contact = () => {
  return (
    <div className="Contact">
      <h1 className="heading">Let's connect!</h1>
      <div className="contactcards-container">
          <Contactcard name="GitHub" color="purple" link="https://github.com/lstuma"><FaGithub size={"3em"}/></Contactcard>
          <Contactcard name="LinkedIn" color="blue" link="https://www.linkedin.com/in/lukas-stockmann-640405239/"><FaLinkedin size={"3em"}/></Contactcard>
          <Contactcard name="Email" color="red" link="lstuma@proton.me"><MdEmail size={"3em"}/></Contactcard>
          <Contactcard name="Devpost" color="orange" link="https://devpost.com/lstuma"><SiDevpost size={"3em"}/></Contactcard>
      </div>
    </div>
  );
}

export default Contact;
