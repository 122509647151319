import { useGLTF } from '@react-three/drei/core/useGLTF';
import MouseRotateModel from '../../../../components/3D/MouseRotateModel.js';
import FloatyModel from '../../../../components/3D/FloatyModel.js';
import './Chef.css';

const Chef = (props) => {
  return (
    <div className="Chef">
      <h1 className="heading prevent-select">DEV LOG <br/><span className="subtitle">(OR SUM LIKE THAT)</span></h1>
    </div>
  );
}

export default Chef;
