import { useState } from 'react';
import { BsFillClipboardCheckFill } from "react-icons/bs";
import { HiMiniClipboardDocumentCheck } from "react-icons/hi2";
import { FaCopy } from "react-icons/fa6";
import { FaClipboardCheck } from "react-icons/fa6";
import { FaCircleCheck } from "react-icons/fa6";
import { FaRegCircleCheck } from "react-icons/fa6";
import './Contact.css';

const Contactcard = (props) => {
  const [copied, setCopied] = useState(false);
  let onclick_callback = async () => {};
  let href_link = props.link;
  if(!props.link.startsWith('https://')) {
    // if not an https link, copy to clipboard instsead
    onclick_callback = async() => {await navigator.clipboard.writeText(props.link); setCopied(true); setTimeout(setCopied, 400, false); return false;};
    href_link = "";
  }
  return (
    <a className={"Contactcard glowy-"+props.color} copied={copied?"true":"false"} href={href_link?href_link:"javascript:void(0)"} onClick={onclick_callback}>
      <div className="icon-container clipboard"><FaRegCircleCheck size={"2.5em"}/></div>
      <div className="icon-container icon">{props.children}</div>
      <div className="name-container"><h1 className="name">{props.name}</h1></div>
    </a>
  );
}

export default Contactcard;
