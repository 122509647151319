import './Whoami.css';
import Profilepic from '../Profilepic/Profilepic.js';

const Whoami = () => {
  return (
    <div className="Whoami">
      <h1 className="heading">About me</h1>
      <div className="about-me-container">
        <div className="pfp-container">
          <Profilepic className="pfp"/>
          <h1 className="pfp-subtitle">lstuma</h1>
        </div>
        <div className="description">
          <ul>
            <li>loves coding</li>
            <li>big time hackathon fan</li>
            <li>runs on coffee</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Whoami;
